export const isClient = typeof window !== 'undefined';

export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null;
  return wpLink.replace(wordPressUrl, '');
}

export const slugify = (string) => {
  return string.toLowerCase().replace(/ /g, '-')
}

export const scrollTo = (elementY, duration) => {
  if (typeof window !== 'undefined') {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);
      window.scrollTo({
        top: startingY + diff * percent,
        left: 0,
        behaviour: 'smooth'
      });

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
}

export const getUrlVars = () => {
  const queryString = isClient && window.location.search;
  if (queryString) {
    const queryParams = queryString.slice(1).split('&');
    return queryParams.reduce((acc, next) => {
      const paramKey = next.split('=')[0];
      const paramVal = next.split('=')[1];
      acc[paramKey] = paramVal;
      return acc;
    }, {});
  }
  return false;
}

// remove = the key (string) to remove
export const compileQuery = (paramKey = null, paramVal = null, remove = null) => {
  const queryParams = isClient && getUrlVars();
  const params = queryParams || {};
  if (remove) {
    delete params[remove];
  }
  if (paramKey && paramVal) {
    params[paramKey] = paramVal;
  }
  const queryString = Object.keys(params).map(queryParamKey => {
    return `${queryParamKey}=${params[queryParamKey]}`;
  }).join('&');
  return queryString ? `?${queryString}` : '';
}

String.prototype.replaceAll = function(str1, str2, ignore) {
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
}

export const trailingSlash = (url) => {
  return url.endsWith("/") ? url : `${url}/`
}

const htmlEntities = {
    nbsp: ' ',
    cent: '¢',
    pound: '£',
    yen: '¥',
    euro: '€',
    copy: '©',
    reg: '®',
    lt: '<',
    gt: '>',
    quot: '"',
    amp: '&',
    apos: '\''
};

export const decodeEntities = (str) => {
  /* eslint no-useless-escape: 0 */
  /* eslint no-cond-assign: 0 */
  /* eslint no-bitwise: 0 */
  if (!str) return str;
  return str.toString().replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match;
    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
    }
    if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
      return String.fromCharCode(parseInt(match[1], 16));
    }
    if (match = entityCode.match(/^#(\d+)$/)) {
        return String.fromCharCode(~~match[1]);
    }
    return entity;
  });
};

export const stripHtmlTags = (content) => {
  return content.replace(/(<([^>]+)>)/gi, "");
};

export const edgeTest = () => {
  //Edge < 40 bug fix
  const appVersion = isClient && navigator.appVersion.indexOf('Edge') > -1
  const appVersionNumber = appVersion && navigator.appVersion.split(' Edge/')[1].split(' ')[0]

  if (appVersionNumber && parseInt(appVersionNumber) < 17) {
    return false
  }

  return true
}
